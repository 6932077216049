import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

const pswpElement = document.querySelector('.pswp');
const container = document.getElementById('imgModule');

if (container !== null) {
    const elements = container.querySelectorAll('img');
    
    for ( let element of elements.values() ) {
        element.addEventListener( 'click', function(event) {
            let index = /[0-9]*[0-9]+/.exec(this.classList.toString());
            let items = getItems(elements);
            var options = {
                index: parseInt(index[0], 10),
                bgOpacity: 0.5,
                fullscreenEl: false,
                // zoomEl: false,
                shareEl: false,
            }
            let gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options );
            gallery.init();
        });
    }
}

function getItems(elements) {
    let items = [];
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        let item = {
            src: element.src,
            w: element.naturalWidth,
            h: element.naturalHeight,
        }
        items.push(item);
    }
    return items;
}