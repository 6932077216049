const container = document.querySelector('[data-element=modalContainer]');

if (container !== null) {

    const options = {
        childList: true,
        subtree: false
    };
    const observer = new MutationObserver(closeModalEvent);
    observer.observe(container, options);

    closeModalEvent();

}

function closeModal(modal) {
    const close = modal.querySelector('.close-modal');
    if (modal !== null) {
        console.log(modal)
        modal.parentElement.removeChild(modal);
    }
}

async function closeModalEvent() {
    if (container.children.length === 0) {
        return;
    }
    for (let index = 0; index < container.children.length; index++) {
        console.log(index, container.children[index]);
        const modal = container.children[index];
        const close = modal.querySelector('.close-modal');
        close.addEventListener('click', ev => closeModal(ev.target.parentElement.parentElement));
    }
}
