const filters = document.getElementById('filters');
const projects = document.getElementById('projects');


if (filters !== null) {
    var filtersNode = filters.querySelectorAll('li');
    var projectsNode = filters.querySelectorAll('li');
    
    for (let index = 0; index < filtersNode.length; index++) {
        const filter = filtersNode[index];
        filter.addEventListener('click', function(ev) {
            toggleActivate(ev.target);
        })
    }

}

// Return an array of all active filters
var getActiveFilters = function() {
    var activeFilters = []
    for (let index = 0; index < filters.querySelectorAll('li').length; index++) {
        const filter = filters.querySelectorAll('li')[index];
        if ( isActive(filter) ) {
            activeFilters.push(filter);
        }
    }
    return activeFilters
}

var isActive = function(filter) {
    if ( filter.classList.contains('active') ) {
        return true;
    }
    return false;
}

var activateAllProjects = function() {
    for (let index = 0; index < projects.querySelectorAll('li').length; index++) {
        const project = projects.querySelectorAll('li')[index];
        if ( project.classList.contains('nactive') ) {
            project.classList.add('active');
            project.classList.remove('nactive');
        }
    }
}

var deactivateProject = function(project) {
    if ( project.classList.contains('active') ) {
        project.classList.remove('active');
        project.classList.add('nactive');
    }
}

var activateProject = function(project) {
    if ( !project.classList.contains('active') ) {
        project.classList.add('active');
        project.classList.remove('nactive');
    }
}

var getFilterName = function(filter) {
    return filter.id;
}

var toggleActivate = function(selection) {

    selection.classList.toggle('active')
    var deactivateFilters = getActiveFilters()

    if ( deactivateFilters.length === filters.querySelectorAll('li').length || deactivateFilters.length === 0 ) {
        activateAllProjects()
    } else  {

        for (let i = 0; i < projects.querySelectorAll('li').length; i++) {
            const project = projects.querySelectorAll('li')[i];

            for (let index = 0; index < deactivateFilters.length; index++) {
                const filter = deactivateFilters[index];
                const filterStr = filter.id
                var test = false

                if ( project.classList.contains(filterStr) ) {
                    test = true
                    break
                }
            }
            if (test) {
                activateProject(project)
            } else {
                deactivateProject(project)
            }

        }

    }

}