export class injectHTML {

    constructor(element = 'div') {
        this.element = document.createElement(element);
        this.url = window.location.origin;
    }

    insertAfter(target) {
        parent = target.parentElement;
    }

    createModal(type, message) {
        let container = document.createElement('div');
        container.dataset.element = "modal";
        container.classList.add('alert-modal');
        container.classList.add(type);
        container.classList.add('alert-modal');
        let iconContainer = document.createElement('div');
        iconContainer.classList.add('alert-icon');
        this.getSvg(type).then(data => iconContainer.insertAdjacentHTML('afterbegin', data));
        container.appendChild(iconContainer);
        let closeContainer = document.createElement('div');
        closeContainer.classList.add('close-modal');
        this.getSvg('x').then(data => closeContainer.insertAdjacentHTML('afterbegin', data));
        container.appendChild(closeContainer);
        let messageContainer = document.createElement('div');
        messageContainer.classList.add('alert-message');
        messageContainer.innerText = message;
        container.appendChild(messageContainer);

        document.querySelector('[data-element=modalContainer]').appendChild(container);
    }

    async getSvg(name) {
        const options = {
            method: 'Get',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'image/svg+xml'
            
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }
        let response = await fetch(this.url + '/images/assets/icon/' + name + '.svg', options);
        if (response.status !== 200) {
            throw console.error('SVG named "' + name + '" not found in ' + this.url);
        }

        return response.text();
    }



}